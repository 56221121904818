import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import PropTypes from 'prop-types'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { jobStateToString } from '../common/utils'
import ApiPB from '../qai_hub/public_api_pb'
import RadialSeparators from './RadialSeparators'

function RunningJobStateProgressIcon(props) {
  var progress = 0
  var current_step = 0
  var total_steps = 3
  if (props.type == 'compile') {
    total_steps = 2
  }

  var color = 'var(--qualcomm_blue)'
  switch (props.state) {
    case ApiPB.JobState.JOB_STATE_FAILED:
      color = 'var(--alert-red)'
      progress = 100
      current_step = total_steps
      break
    case ApiPB.JobState.JOB_STATE_DONE:
      color = 'var(--green)'
      progress = 100
      current_step = total_steps
      break
    case ApiPB.JobState.JOB_STATE_CREATED:
      progress = 5
      current_step = 0
      break
    case ApiPB.JobState.JOB_STATE_OPTIMIZING_MODEL:
      progress = 49.2
      current_step = 1
      break
    case ApiPB.JobState.JOB_STATE_PROVISIONING_DEVICE:
      progress = 38.3
      current_step = 1
      break
    case ApiPB.JobState.JOB_STATE_RUNNING_INFERENCE:
      progress = 71.6
      current_step = 2
      break
    case ApiPB.JobState.JOB_STATE_MEASURING_PERFORMANCE:
      progress = 71.6
      current_step = 2
      break
    default:
      progress = 0
  }
  return (
    <CircularProgressbarWithChildren
      value={progress}
      text={props.show_text ? `${current_step}/${total_steps}` : ''}
      strokeWidth={10}
      styles={{
        path: {
          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: 'butt',
          stroke: color,
        },
        text: {
          fill: color,
          fontWeight: 'bold',
          fontSize: props.text_size ? props.text_size : '',
        },
      }}
    >
      <RadialSeparators
        count={total_steps}
        style={{
          width: '2px',
          backgroundColor: 'white',
          height: '15%',
        }}
      />
    </CircularProgressbarWithChildren>
  )
}

RunningJobStateProgressIcon.propTypes = {
  state: PropTypes.number.isRequired,
  show_text: PropTypes.bool,
  text_size: PropTypes.number,
  type: PropTypes.string.isRequired,
}

export function CompileJobStateIconTooltip() {
  return (
    'Status sequence:\n' +
    '- ' +
    jobStateToString(ApiPB.JobState.JOB_STATE_CREATED) +
    '\n' +
    '- ' +
    jobStateToString(ApiPB.JobState.JOB_STATE_OPTIMIZING_MODEL) +
    '\n' +
    '- ' +
    jobStateToString(ApiPB.JobState.JOB_STATE_DONE) +
    ' / ' +
    jobStateToString(ApiPB.JobState.JOB_STATE_FAILED)
  )
}

export function ProfileJobStateIconTooltip(includesCompile) {
  if (includesCompile) {
    return (
      'Status sequence:\n' +
      '- ' +
      jobStateToString(ApiPB.JobState.JOB_STATE_CREATED) +
      '\n' +
      '- ' +
      jobStateToString(ApiPB.JobState.JOB_STATE_OPTIMIZING_MODEL) +
      '\n' +
      '- ' +
      jobStateToString(ApiPB.JobState.JOB_STATE_PROVISIONING_DEVICE) +
      '\n' +
      '- ' +
      jobStateToString(ApiPB.JobState.JOB_STATE_MEASURING_PERFORMANCE) +
      '\n' +
      '- ' +
      jobStateToString(ApiPB.JobState.JOB_STATE_DONE) +
      ' / ' +
      jobStateToString(ApiPB.JobState.JOB_STATE_FAILED)
    )
  } else {
    return (
      'Status sequence:\n' +
      '- ' +
      jobStateToString(ApiPB.JobState.JOB_STATE_CREATED) +
      '\n' +
      '- ' +
      jobStateToString(ApiPB.JobState.JOB_STATE_PROVISIONING_DEVICE) +
      '\n' +
      '- ' +
      jobStateToString(ApiPB.JobState.JOB_STATE_MEASURING_PERFORMANCE) +
      '\n' +
      '- ' +
      jobStateToString(ApiPB.JobState.JOB_STATE_DONE) +
      ' / ' +
      jobStateToString(ApiPB.JobState.JOB_STATE_FAILED)
    )
  }
}

export function InferenceJobStateIconTooltip() {
  return (
    'Status sequence:\n' +
    '- ' +
    jobStateToString(ApiPB.JobState.JOB_STATE_CREATED) +
    '\n' +
    '- ' +
    jobStateToString(ApiPB.JobState.JOB_STATE_PROVISIONING_DEVICE) +
    '\n' +
    '- ' +
    jobStateToString(ApiPB.JobState.JOB_STATE_RUNNING_INFERENCE) +
    '\n' +
    '- ' +
    jobStateToString(ApiPB.JobState.JOB_STATE_DONE) +
    ' / ' +
    jobStateToString(ApiPB.JobState.JOB_STATE_FAILED)
  )
}

export function JobStateIcon(props) {
  let element = ''

  switch (props.state) {
    case ApiPB.JobState.JOB_STATE_FAILED:
      element = <ErrorRoundedIcon sx={{ fontSize: '26px', color: 'var(--alert_red)' }} />
      break
    case ApiPB.JobState.JOB_STATE_DONE:
      element = <CheckCircleRoundedIcon sx={{ fontSize: '26px', color: 'var(--green)' }} />
      break
    default:
      element = <RunningJobStateProgressIcon state={props.state} show_text={true} text_size={40} type={props.type} />
      break
  }

  return (
    <div className="state-icon" style={{ marginRight: props.marginRight }}>
      <div className="state-icon-inner">{element}</div>
    </div>
  )
}

JobStateIcon.propTypes = {
  state: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  marginRight: PropTypes.string,
}
