import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Alert from '@mui/material/Alert'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { authSSOLogin, getAuthUser, getOrganization, useMounted } from '../common/restAPI'
import { FullPageWait } from './FullPageWait'

LogIn.propTypes = {
  loginCallback: PropTypes.func.isRequired,
  ssoResponseCode: PropTypes.string,
}

export default function LogIn({ loginCallback: login, ssoResponseCode }) {
  const navigate = useNavigate()
  const loc = useLocation()
  // TODO: For SSO login, 'from' will not be in the location state; we have to unstash it from somewhere
  // https://github.com/qcom-ai-hub/tetracode/issues/8638
  const from = loc.state?.from?.pathname || '/'

  const loggingIn = !!ssoResponseCode

  const [errors, setErrors] = useState(false)

  const loginFailed = () => {
    console.log('Failed to sign in')
    localStorage.clear()
    setErrors(true)
  }

  const onUserTokenReceived = (key, mountState) => {
    if (!key) {
      loginFailed()
      return
    }

    localStorage.setItem('token', key)

    // Get the full user info
    getAuthUser(
      mountState,
      (info) => {
        getOrganization(
          info.getOrgId(),
          mountState,
          (orgInfo) => {
            login(info, orgInfo, key)
            setErrors(false)
            navigate(from, { replace: true })
          },
          loginFailed,
        )
      },
      loginFailed,
    )
  }

  useEffect(() => {
    document.title = 'Sign In | AI Hub'
  }, [])

  // attempt sso login with code returned from openID callback redirect
  useEffect(() => {
    let [mountState, tearDownMounted] = useMounted()

    if (ssoResponseCode) {
      authSSOLogin(
        ssoResponseCode,
        mountState,
        (key) => onUserTokenReceived(key, mountState),
        () => setErrors(true),
      )
    }

    return tearDownMounted
  }, [ssoResponseCode])

  if (loggingIn && !errors) {
    return <FullPageWait />
  }

  return (
    <div className="main">
      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            marginTop: 8,
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 0.5,
            paddingBottom: 0.5,
            backgroundColor: 'white',
            boxShadow: '0 2px 4px 0 rgba(0,0,0,.2)',
          }}
        >
          <Box
            sx={{
              marginTop: 8,
              marginBottom: 5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Button href={'/accounts/sso_login/'} fullWidth variant="contained" sx={{ mt: 5, mb: 5 }}>
              Sign in with Qualcomm MyAccount
            </Button>
            {errors && <Alert severity="error">Unable to sign in. New User? Please try again in a few minutes.</Alert>}
          </Box>
        </Box>
      </Container>
    </div>
  )
}
