import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EmailIcon from '@mui/icons-material/Email'
import ErrorIcon from '@mui/icons-material/Error'
import { Link, Skeleton, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import TextField from '@mui/material/TextField'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { getJobSharedAccess, modifyJobSharedAccess, useMounted } from '../common/restAPI'
import { SharedAccessEmailList } from '../common/shared-access-email-list'

/**
 * @typedef {'compile' | 'profile' | 'inference'} ShareDialogJobType
 */

const jobTypes = ['compile', 'profile', 'inference']

export const TestIds = {
  EMAIL_LIST: 'emailList',
  LOADING_SKELETON: 'loadingSkeleton',
  ERROR_LIST: 'errorList',
}

ShareDialog.propTypes = {
  jobId: PropTypes.string.isRequired,
  jobType: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  insertArtificialDelaysForUITesting: PropTypes.bool,
}

const ARTIFICIAL_DELAY_MS = 3000

export default function ShareDialog({
  jobId,
  jobType,
  isOpen = false,
  onClose,
  insertArtificialDelaysForUITesting = false,
}) {
  if (!jobTypes.includes(jobType)) {
    throw new Error(`Invalid jobType - should be one of ${JSON.stringify(jobTypes)}`)
  }

  const [emailList, setEmailList] = useState(new SharedAccessEmailList([]))
  const [isLoadingInitialList, setIsLoadingInitialList] = useState(true)
  const [isUpdatingItem, setIsUpdatingItem] = useState(false)
  const [hasErrorInInitialFetch, setHasErrorInInitialFetch] = useState(false)

  const { dialogTitle, dialogText } = getDialogTitleAndText(jobType)
  const disabled = isUpdatingItem || isLoadingInitialList

  // Load the initial email list of who has access to this job.
  useEffect(() => {
    if (!isOpen) {
      return
    }

    const [mountState, tearDownMounted] = useMounted()

    setIsLoadingInitialList(true)

    const onSuccess = (sharedAccessPb) => {
      const emails = sharedAccessPb.getEmailList()
      setEmailList(SharedAccessEmailList.fromEmails(emails))
      setIsLoadingInitialList(false)
    }

    getJobSharedAccess(
      jobId,
      mountState,
      (sharedAccessPb) => {
        if (insertArtificialDelaysForUITesting) {
          setTimeout(() => onSuccess(sharedAccessPb), ARTIFICIAL_DELAY_MS)
        } else {
          onSuccess(sharedAccessPb)
        }
      },
      () => {
        setHasErrorInInitialFetch(true)
        setIsLoadingInitialList(false)
      },
    )

    return tearDownMounted
  }, [jobId, isOpen])

  const pushChanges = ({ emailToAdd, emailToRemove }) => {
    const [mountState] = useMounted()

    if ((emailToAdd && emailToRemove) || (!emailToAdd && !emailToRemove)) {
      throw new Error(`Supply either emailToAdd or emailToRemove, but not both.`)
    }

    setIsUpdatingItem(true)

    const onSuccess = () => {
      setIsUpdatingItem(false)

      // When adding an email, mark it as done. Otherwise, remove it from the list now.
      if (emailToAdd) {
        setEmailList((list) => list.setStatus(emailToAdd, 'done'))
      } else {
        setEmailList((list) => list.removeEmail(emailToRemove))
      }
    }

    modifyJobSharedAccess(
      jobId,
      emailToAdd ? [emailToAdd] : [],
      emailToRemove ? [emailToRemove] : [],
      mountState,
      () => {
        if (insertArtificialDelaysForUITesting) {
          setTimeout(onSuccess, ARTIFICIAL_DELAY_MS)
        } else {
          onSuccess()
        }
      },
      () => {
        setIsUpdatingItem(false)
        setEmailList((list) => list.setStatus(emailToAdd ?? emailToRemove, 'hasError'))
      },
    )
  }

  const handleAddClick = (emailToAdd) => {
    setEmailList(emailList.addEmail(emailToAdd, 'adding'))
    pushChanges({ emailToAdd })
  }

  const handleRemoveClick = (emailToRemove) => {
    // Don't push changes if the email in the list wasn't "really" added (it's currently processing or has errors).
    const shouldPushChanges = emailList.getStatus(emailToRemove) === 'done'
    if (shouldPushChanges) {
      setEmailList(emailList.setStatus(emailToRemove, 'removing'))
      pushChanges({ emailToRemove })
    } else {
      setEmailList(emailList.removeEmail(emailToRemove))
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="share-dialog-title"
      aria-describedby="share-dialog-description"
    >
      <DialogTitle id="share-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="share-dialog-description">{dialogText}</DialogContentText>

        {isLoadingInitialList ? (
          <LoadingSkeleton />
        ) : hasErrorInInitialFetch ? (
          <InitialListLoadError />
        ) : (
          <>
            <LoadedListSection disabled={disabled} emailList={emailList} onRemove={handleRemoveClick} />
            <AddEmailSection disabled={disabled} emailList={emailList} onAdd={handleAddClick} />
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

/**
 * @typedef DialogTitleAndText
 * @property {string} dialogTitle
 * @property {string} dialogText
 *
 * Gets the dialog title and text depending on the job type.
 * @param {ShareDialogJobType} jobType
 * @returns {Readonly<DialogTitleAndText>}
 */
function getDialogTitleAndText(jobType) {
  switch (jobType) {
    case 'compile':
      return {
        dialogTitle: 'Share Compile Job',
        dialogText: 'Share this job and its associated source/target models with the following email addresses.',
      }
    case 'profile':
      return {
        dialogTitle: 'Share Profile Job',
        dialogText:
          'Share this job and its associated assets (the producing job and source/target models) with the following email addresses.',
      }
    case 'inference':
      return {
        dialogTitle: 'Share Inference Job',
        dialogText:
          'Share this job and its associated assets (the producing job, source/target models, and input/output datasets) with the following email addresses.',
      }
  }
}

function LoadingSkeleton() {
  return (
    <List data-testid={TestIds.LOADING_SKELETON}>
      {Array.of('first', 'second').map((item) => (
        <ListItem key={item} secondaryAction={<Skeleton variant="rounded" width="1.5rem" height="1.5rem" />}>
          <ListItemText>
            <Skeleton variant="text" sx={{ fontSize: '1rem' }}></Skeleton>
          </ListItemText>
        </ListItem>
      ))}
    </List>
  )
}

function InitialListLoadError() {
  return (
    <List data-testid={TestIds.ERROR_LIST}>
      <ListItem sx={{ gap: 1 }}>
        <ErrorIcon color="error" />
        <ListItemText>Error loading access list.</ListItemText>
        <EmailIcon color="secondary" />
        <Link href="mailto:ai-hub-support@qti.qualcomm.com">Contact support</Link>
      </ListItem>
    </List>
  )
}

LoadedListSection.propTypes = {
  disabled: PropTypes.bool.isRequired,
  emailList: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
}

/**
 * @typedef LoadedListSectionProps
 * @property {boolean} disabled
 * @property {SharedAccessEmailList} emailList
 * @property {(emailToRemove: string) => void} onRemove
 *
 * @param {LoadedListSectionProps} props
 * @returns
 */
function LoadedListSection({ disabled, emailList, onRemove }) {
  return (
    <List data-testid={TestIds.EMAIL_LIST} sx={{ maxHeight: 300, overflowX: 'hidden', overflowY: 'auto' }}>
      {emailList.items.map(({ email, status }) => (
        <ListItem
          key={email}
          secondaryAction={
            <IconButton edge="end" aria-label="Remove" disabled={disabled} onClick={() => onRemove(email)}>
              <DeleteIcon color={status === 'hasError' ? 'error' : 'inherit'} />
            </IconButton>
          }
        >
          {/*
           * Show the item with strikethrough if it's being removed.
           * It'll get removed from the list when the update happens.
           */}
          <ListItemText>
            <Typography
              color={status === 'done' ? 'inherit' : status === 'hasError' ? 'error' : 'secondary'}
              sx={status === 'removing' ? { textDecoration: 'line-through' } : {}}
            >
              {email}
            </Typography>
          </ListItemText>
        </ListItem>
      ))}

      {emailList.items.some(({ status }) => status === 'hasError') && (
        <ListItem sx={{ gap: 1 }}>
          <ErrorIcon color="error" />
          <ListItemText>Error in modifying access list.</ListItemText>
          <EmailIcon color="secondary" />
          <Link href="mailto:ai-hub-support@qti.qualcomm.com">Contact support</Link>
        </ListItem>
      )}
    </List>
  )
}

function isValidEmail(email) {
  // Taken from MDN here: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#basic_validation
  const pattern =
    /^[\w!#$%&'*+./=?^`{|}~-]+@[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?(?:\.[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?)*$/

  return pattern.test(email)
}

AddEmailSection.propTypes = {
  disabled: PropTypes.bool.isRequired,
  emailList: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired,
}

/**
 * @typedef {Object} AddEmailSectionProps
 * @property {boolean} disabled
 * @property {SharedAccessEmailList} emailList
 * @property {(emailToAdd: string) => void} onAdd
 *
 * @param {AddEmailSectionProps} props
 */
function AddEmailSection({ disabled, emailList, onAdd }) {
  const [emailInputValue, setEmailInputValue] = useState('')
  const [isEmailInputValueValid, setIsEmailInputValueValid] = useState(false)

  const handleAddClick = () => {
    const emailToAdd = emailInputValue
    if (!emailList.hasEmail(emailToAdd)) {
      onAdd(emailToAdd)
    }

    setEmailInputValue('')
  }

  const handleEmailInputChange = (e) => {
    const email = e.target.value
    setEmailInputValue(email)
    setIsEmailInputValueValid(isValidEmail(email))
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()

    if (!disabled) {
      handleAddClick()
    }
  }

  return (
    <ListItem
      component="div"
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="Add"
          onClick={handleAddClick}
          disabled={disabled || !isEmailInputValueValid || emailInputValue === ''}
          sx={{ marginTop: '12px' }}
        >
          <AddIcon />
        </IconButton>
      }
    >
      <Box component="form" sx={{ flexBasis: '100%' }} onSubmit={handleFormSubmit}>
        <TextField
          type="email"
          variant="standard"
          id="email"
          name="email"
          label="Add email"
          autoFocus
          fullWidth
          value={emailInputValue}
          error={!isEmailInputValueValid && emailInputValue !== ''}
          onChange={handleEmailInputChange}
        />
      </Box>
    </ListItem>
  )
}
