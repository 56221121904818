/**
 * @typedef {('adding' | 'removing' | 'hasError' | 'done')} SharedAccessEmailItemStatus
 *
 * @typedef {Object} SharedAccessEmailItem
 * @property {string} email
 * @property {SharedAccessEmailItemStatus} status
 */

/**
 * Helper class for managing the list of emails that have access to a particular job. Note that this does NOT handle
 * communicating to the server. It just handles the state of the list.
 *
 * The class is immutable. Any state changes will return a new list.
 */
export class SharedAccessEmailList {
  /**
   * Used for fast lookups of the emails in the list.
   * @type {ReadonlySet<string>}
   */
  // eslint-disable-next-line @typescript-eslint/prefer-readonly
  #emails = new Set()

  /** @type {readonly Readonly<SharedAccessEmailItem>[]} */
  // eslint-disable-next-line @typescript-eslint/prefer-readonly
  #items = []

  /**
   * Gets the items, sorted by email address.
   */
  get items() {
    return this.#items
  }

  /**
   * Constructs a new instance of the {@link SharedAccessEmailList} class with the specified email items.
   * @param {readonly Readonly<SharedAccessEmailItem>[]} items The items to use for populating the list.
   */
  constructor(items) {
    this.#emails = new Set(items.map(({ email }) => email.toLowerCase()))

    if (this.#emails.size !== items.length) {
      throw new Error(`Duplicate email addresses detected.`)
    }

    this.#items = [...items].sort(({ email: a }, { email: b }) => (a <= b ? -1 : 1))
  }

  /**
   * Returns a value indicating whether the email is in the list (case-insensitive).
   * @param {string} email
   * @returns {boolean} A value indicating whether the email is in the list.
   */
  hasEmail(email) {
    return this.#emails.has(email.toLowerCase())
  }

  /**
   * Returns the status of the specified email.
   * @param {string} email The email to get status for.
   * @returns {SharedAccessEmailItemStatus} The status of the email.
   */
  getStatus(email) {
    const foundItem = this.#items.find(({ email: itemEmail }) => itemEmail.toLowerCase() === email.toLowerCase())

    if (foundItem === undefined) {
      throw new Error(`Email '${email}' is not in the list.`)
    }

    return foundItem.status
  }

  /**
   * Sets the status for the specified email and returns a new instance of a {@link SharedAccessEmailList}.
   * @param {string} email The email to set status for.
   * @param {SharedAccessEmailItemStatus} status The status of the email.
   * @returns {SharedAccessEmailList} A new instance of the list with the state changed on the item.
   */
  setStatus(email, status) {
    const lowerEmail = email.toLowerCase()
    const allButThisEmail = this.#items.filter(({ email: itemEmail }) => itemEmail.toLowerCase() !== lowerEmail)

    if (allButThisEmail.length !== this.#items.length - 1) {
      throw new Error(`Email '${email}' is not in the list.`)
    }

    const newList = [...allButThisEmail, { email, status }]
    return new SharedAccessEmailList(newList)
  }

  /**
   * Adds a new email address with the specified status
   * @param {string} email The email to add
   * @param {SharedAccessEmailItemStatus} status The status of the item to add.
   * @returns {SharedAccessEmailList} A new instance of the list with the item added.
   */
  addEmail(email, status = 'adding') {
    return new SharedAccessEmailList([...this.#items, { email, status }])
  }

  /**
   * Removes the email from the list.
   * @param {string} email The email to remove.
   * @returns {SharedAccessEmailList} A new instance of the list with the item removed.
   */
  removeEmail(email) {
    const lowerEmail = email.toLowerCase()
    const newList = this.#items.filter(({ email: itemEmail }) => itemEmail.toLowerCase() !== lowerEmail)

    if (newList.length !== this.#items.length - 1) {
      throw new Error(`Email '${email}' is not in the list.`)
    }

    return new SharedAccessEmailList(newList)
  }
}

/**
 * Creates a new instance of a {@link SharedAccessEmailList} from the supplied email addresses. All of them will have
 * the specified status.
 * @param {readonly string[]} emails The email addresses, all of which will have the supplied status.
 * @param {SharedAccessEmailItemStatus} status The status to assign to each email.
 */
SharedAccessEmailList.fromEmails = function fromEmails(emails, status = 'done') {
  return new SharedAccessEmailList(emails.map((email) => ({ email, status })))
}
