import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Alert from '@mui/material/Alert'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { authLogin, getAuthUser, getOrganization, useMounted } from '../common/restAPI'

LogInEmail.propTypes = {
  loginCallback: PropTypes.func.isRequired,
}

export default function LogInEmail({ loginCallback: login }) {
  const navigate = useNavigate()
  const loc = useLocation()
  const from = loc.state?.from?.pathname || '/'

  const [errors, setErrors] = useState(false)

  useEffect(() => {
    document.title = 'Email Sign In | AI Hub'
  }, [])

  const loginFailed = () => {
    console.log('Failed to sign in')
    localStorage.clear()
    setErrors(true)
  }

  const onUserTokenReceived = (key, mountState) => {
    if (!key) {
      loginFailed()
      return
    }

    localStorage.setItem('token', key)

    // Get the full user info
    getAuthUser(
      mountState,
      (info) => {
        getOrganization(
          info.getOrgId(),
          mountState,
          (orgInfo) => {
            login(info, orgInfo, key)
            setErrors(false)
            navigate(from, { replace: true })
          },
          loginFailed,
        )
      },
      loginFailed,
    )
  }

  // attempt sign in with email and password
  const onSubmit = (e) => {
    e.preventDefault()
    let [mountState, tearDownMounted] = useMounted()

    const data = new FormData(e.currentTarget)
    const email = data.get('email')
    const password = data.get('password')

    authLogin(
      email,
      password,
      mountState,
      (key) => onUserTokenReceived(key, mountState),
      () => setErrors(true),
    )

    return tearDownMounted
  }

  return (
    <div className="main">
      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            marginTop: 8,
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 0.5,
            paddingBottom: 0.5,
            backgroundColor: 'white',
            boxShadow: '0 2px 4px 0 rgba(0,0,0,.2)',
          }}
        >
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            {errors && <Alert severity="error">Unable to sign in with the credentials provided.</Alert>}
            <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 3 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 7 }}>
                Sign In with Email and Password
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  )
}
