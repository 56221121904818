import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getModels, useMounted } from '../common/restAPI'
import { modelTypeToString } from '../common/utils'
import TablePaginationActions from './TablePaginationActions'

export default function ListModels(props) {
  const rowsPerPage = 10
  const [modelList, setModelList] = useState([])
  const [totalQueryCount, setTotalQueryCount] = useState(0)
  const [page, setPage] = useState(props.page ?? 0)

  const handleChangePage = (event, newPage) => {
    if (newPage != page) {
      let url = '/models/'
      if (newPage >= 1) {
        url += '?page=' + newPage
      }
      window.history.replaceState(null, '', url)
      setPage(newPage)
    }
  }

  useEffect(() => {
    document.title = 'Models | AI Hub'
  }, [])

  useEffect(() => {
    let [mountState, tearDownMounted] = useMounted()

    getModels(
      page,
      rowsPerPage,
      mountState,
      (modelListPb) => {
        setModelList(modelListPb.getModelsList())
        setTotalQueryCount(modelListPb.getTotalQueryCount())
      },
      (err) => {
        console.log(err)
      },
    )

    return tearDownMounted
  }, [page])

  const renderJobs = () => {
    let elements = []
    if (modelList && Array.isArray(modelList)) {
      elements = modelList.map((item) => (
        <TableRow key={item.getModelId()}>
          <TableCell style={{ maxWidth: '25vw' }}>
            <Link
              to={`/models/${item.getModelId()}/`}
              style={{
                display: 'block',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {item.getName()}
            </Link>
          </TableCell>
          <TableCell>
            <span className="idbox">{item.getModelId()}</span>
          </TableCell>
          <TableCell>{modelTypeToString(item.getModelType())}</TableCell>
          <TableCell>{item.getOwner().getEmail()}</TableCell>
          <TableCell>{item.getCreationTime().toDate().toLocaleString()}</TableCell>
        </TableRow>
      ))
    }

    return (
      <TableContainer component={Paper}>
        <Table className="table">
          <TableHead>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                colSpan={7}
                count={totalQueryCount}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: false,
                }}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
            <TableRow key="header">
              <TableCell xs={600}>Model Name</TableCell>
              <TableCell>Model ID</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Creator</TableCell>
              <TableCell>Creation Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{elements}</TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <div className="main">
      <Typography variant="h5" style={{ marginBottom: '0.5em' }}>
        Models
      </Typography>
      {renderJobs()}
    </div>
  )
}

ListModels.propTypes = {
  page: PropTypes.number,
}
