import DownloadIcon from '@mui/icons-material/Download'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { default as React, useId, useState } from 'react'
import { getJobArtifact, useMounted } from '../common/restAPI'
import { capitalizeFirstLetter } from '../common/utils'

function JobLog(props) {
  const { jobId, artifactType, name, doesNotExistMessage } = props
  const capitalizedName = capitalizeFirstLetter(name)
  const [logFileContents, setLogFileContents] = useState('')
  const [logFileUrl, setLogFileUrl] = useState('')
  const [isLogFileTruncated, setLogFileTruncated] = useState(false)
  const [doesLogFileExist, setDoesLogFileExist] = useState(false)

  const id = useId()

  const downloadLogArtifact = () => {
    const [mountState] = useMounted()
    getJobArtifact(
      jobId,
      'latest',
      artifactType,
      mountState,
      (url) => {
        setLogFileUrl(url)
        setDoesLogFileExist(true)
        const headers = new Headers()
        // 384k is determined heuristically - it takes too long to close the accordian with more than that
        // https://docs.aws.amazon.com/whitepapers/latest/s3-optimizing-performance-best-practices/use-byte-range-fetches.html
        // "Typical sizes for byte-range requests are 8 MB or 16 MB."
        const maxDisplayLength = 384 * 1024
        const maxRequestLength = 8 * 1024 * 1024
        headers.append('Range', `bytes=0-${maxRequestLength}`)

        const request = new Request(url, { headers: headers })
        fetch(request)
          .then((response) => response.text())
          .then((logContents) => {
            setLogFileTruncated(logContents.length > maxDisplayLength)
            setLogFileContents(logContents.substring(0, maxDisplayLength))
          })
      },
      () => {
        setLogFileContents(doesNotExistMessage)
        setDoesLogFileExist(false)
      },
    )
  }

  const handleAccordionChange = (_event, expanded) => {
    // Only download once (if the logFileContents is empty)
    if (expanded && !logFileContents) {
      downloadLogArtifact()
    }
  }

  const DownloadLogLink = React.forwardRef((props, ref) => {
    return <a ref={ref} href={logFileUrl} download={`${capitalizedName}Log_${jobId}.log`} {...props} />
  })
  DownloadLogLink.displayName = 'DownloadLogLink'

  const title = `${capitalizedName} Log`
  return (
    <Accordion onChange={handleAccordionChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={id} id={id}>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ overflow: 'hidden', paddingTop: '0px' }}>
        <Stack direction="column" alignItems="flex-start" spacing={1} useFlexGap>
          {doesLogFileExist && (
            <Button
              component={DownloadLogLink}
              sx={{ pt: 0.3, pb: 0.3 }}
              size="small"
              startIcon={<DownloadIcon />}
              variant="outlined"
            >
              {title}
            </Button>
          )}
          <pre style={{ fontSize: '9pt', whiteSpace: 'pre-wrap' }}>{logFileContents}</pre>
          {isLogFileTruncated && (
            <p>The log has been truncated in this preview. Please download to view the complete log.</p>
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

JobLog.displayName = 'JobLog'
JobLog.propTypes = {
  jobId: PropTypes.string.isRequired,
  artifactType: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  doesNotExistMessage: PropTypes.string.isRequired,
}

export function JobRuntimeLog(props) {
  // Notice that JOB_ARTIFACT_TYPE_TUNGSTEN_LOG must equal the private_api_pb.JobArtifactType.JOB_ARTIFACT_TYPE_TUNGSTEN_LOG
  const JOB_ARTIFACT_TYPE_TUNGSTEN_LOG = 1
  return (
    <JobLog
      artifactType={JOB_ARTIFACT_TYPE_TUNGSTEN_LOG}
      name="runtime"
      doesNotExistMessage="-- Failure occurred prior to runtime log initialization. --"
      {...props}
    />
  )
}

JobRuntimeLog.displayName = 'JobRuntimeLog'
JobRuntimeLog.propTypes = {
  jobId: PropTypes.string.isRequired,
}

export function JobCompileLog(props) {
  // Notice that JOB_ARTIFACT_TYPE_COMPILE_LOG must equal the private_api_pb.JobArtifactType.JOB_ARTIFACT_TYPE_COMPILE_LOG
  const JOB_ARTIFACT_TYPE_COMPILE_LOG = 5
  return (
    <JobLog
      artifactType={JOB_ARTIFACT_TYPE_COMPILE_LOG}
      name="compile"
      doesNotExistMessage="-- Compile log was not captured. --"
      {...props}
    />
  )
}

JobCompileLog.displayName = 'JobCompileLog'
JobCompileLog.propTypes = {
  jobId: PropTypes.string.isRequired,
}
